import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import * as Auth from './authentication'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/default',
    // name: 'default',
    component: () => import(/* webpackChunkName: "default" */ '@/views/default/Main.vue'),
    children: [
      {
        path: '/',
        name: 'UserHome',
        component: () => import(/* webpackChunkName: "user-home" */ '@/views/default/pages/Home.vue'),
        beforeEnter: Auth.requiresUser
      },
      // {
      //   path: 'emra',
      //   name: 'EMRAPage',
      //   component: () => import(/* webpackChunkName: "node-data" */ '@/views/default/pages/EMRAPage.vue'),
      //   beforeEnter: Auth.requiresUser
      // },
      {
        path: 'node',
        name: 'NodeData',
        component: () => import(/* webpackChunkName: "node-data" */ '@/views/default/pages/NodeData.vue'),
        beforeEnter: Auth.requiresUser
      },
      {
        path: 'data',
        name: 'DataPage',
        component: () => import(/* webpackChunkName: "node-data" */ '@/views/default/pages/DataPage.vue'),
        beforeEnter: Auth.requiresUser
      }
    ],
    beforeEnter: Auth.requiresUser
  },
  {
    path: '/mod',
    // name: 'mod',
    component: () => import(/* webpackChunkName: "default" */ '@/views/default/Main.vue'),
    children: [
      {
        path: '/',
        name: 'ModHome',
        component: () => import(/* webpackChunkName: "user-home" */ '@/views/default/pages/Home.vue'),
        beforeEnter: Auth.requiresModerator
      },
      {
        path: 'emra',
        name: 'ModEMRAPage',
        component: () => import(/* webpackChunkName: "emra-page" */ '@/views/default/pages/EMRAPage.vue'),
        beforeEnter: Auth.requiresModerator
      },
      {
        path: 'node',
        name: 'ModNodeData',
        component: () => import(/* webpackChunkName: "node-data" */ '@/views/default/pages/NodeData.vue'),
        beforeEnter: Auth.requiresModerator
      },
      {
        path: 'mod-node',
        name: 'ModNode',
        component: () => import(/* webpackChunkName: "mod-node" */ '@/views/default/pages/ModNode.vue'),
        beforeEnter: Auth.requiresModerator
      },
      {
        path: 'add-emra',
        name: 'AddEMRA',
        component: () => import(/* webpackChunkName: "add-emra" */ '@/views/default/pages/AddEMRA.vue'),
        beforeEnter: Auth.requiresModerator
      },
      {
        path: 'data',
        name: 'ModDataPage',
        component: () => import(/* webpackChunkName: "data" */ '@/views/default/pages/DataPage.vue'),
        beforeEnter: Auth.requiresModerator
      }
    ],
    beforeEnter: Auth.requiresModerator
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "forbidden" */ '@/views/auth/Forbidden.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Main.vue'),
    children: [
      {
        path: 'create-user',
        name: 'CreateUser',
        component: () => import(/* webpackChunkName: "create-user" */ '@/views/admin/CRUD/CreateUser.vue'),
        beforeEnter: Auth.requiresAdmin
      },
      {
        path: 'create-model',
        name: 'CreateModel',
        component: () => import(/* webpackChunkName: "create-model" */ '@/views/admin/CRUD/CreateModel.vue'),
        beforeEnter: Auth.requiresAdmin
      },
    ],
    beforeEnter: Auth.requiresAdmin,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => Auth.logOut()
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/auth/Register.vue')
  },
  {
    path: '*',
    redirect: '/login'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  Auth.keepAliveHealthyTokens()
  next()
})


export default router
