import { http } from '@/http-common'

import * as Response from '@/types/network/response'

import { Default as State, NodeInfo, NodeInfoItem } from '@/types/state'
import { Default as Commit } from '@/types/mutations'


export default {
    namespaced: true,
    state: {
        userId: "",
        nodes: [],
        detailNode: null,
        comparedNodes: [],
        nodeUserRelations: [],
        updatedMeters: [],
        preloaded: false
    },
    mutations: {
        SET_USER_ID(state: State, userId: string): void {
            state.userId = userId
        },
        SET_NODES(state : State, nodes: Array<NodeInfo>): void {
            state.nodes = nodes
            state.preloaded = true
        },
        UPDATE_NODE_IN_LIST(state : State, node: NodeInfo): void {
            const nodeInList = state.nodes.findIndex(n => n.id == node.id)
            if (nodeInList) {
                state.nodes.splice(nodeInList, 1)
                state.nodes.push(node)
            }
        },
        SET_DETAIL_NODE(state : State, node: NodeInfoItem): void {
            state.detailNode = node
            state.preloaded = true
        },
        SET_NODE_USER_RELATIONS(state : State, relations: any): void {
            state.nodeUserRelations = relations
        },
        SET_UPDATED_METERS(state : State, updatedMeters: any): void {
            state.updatedMeters = updatedMeters
        },
        ADD_UPDATED_METER(state : State, meterUpdate: any): void {
            if (!state.updatedMeters.find((um: any) => um.nodeId == meterUpdate.nodeId && um.meterId == meterUpdate.meterId))
                state.updatedMeters.push(meterUpdate)
        },
        DELETE_UPDATED_METERS(state: State, nodeId: string): void {
            const nodesWithThisId = state.updatedMeters.filter((el: any) => el.nodeId == nodeId)
            nodesWithThisId.forEach((el: any) => {
                state.updatedMeters.splice(state.updatedMeters.findIndex((el2: any) => el2.nodeId == nodeId && el2.meterId == el.meterId), 1)
            })
        },
        DELETE_USER_RELATION(state: State, nodeAndUserId: any): void {
            const index = state.nodeUserRelations.findIndex((r: any) => r.nodeId == nodeAndUserId.nodeId && r.user.id == nodeAndUserId.userId)
            if (index > -1)
                state.nodeUserRelations.splice(index, 1)
        },
        // Comparison Menu,
        TOGGLE_COMPARISON(state : State, node: NodeInfoItem): void {
            const existingNode = state.comparedNodes.filter(n => n.id == node.id)[0]
            if (existingNode == null) {
                state.comparedNodes.push(node)
            } 
            else {
                state.comparedNodes
                    .splice(state.comparedNodes
                        .indexOf(existingNode), 1)
            }
        },
        ADD_TO_COMPARISON(state : State, node: NodeInfoItem): void {
            const existingNode = state.comparedNodes.filter(n => n.id == node.id)[0]
            if (existingNode == null) {
                state.comparedNodes.push(node)
            }
        },
        COMPARED_NODE_MOVE_UP(state : State, node: NodeInfoItem): void {
            const index = state.comparedNodes.indexOf(node)
            if (index > 0) {
                state.comparedNodes.splice(
                    index - 1,
                    0,
                    state.comparedNodes.splice(index, 1)[0])
            }
        },
        COMPARED_NODE_MOVE_DOWN(state : State, node: NodeInfoItem): void {
            const index = state.comparedNodes.indexOf(node)
            if (index > -1 && index < state.comparedNodes.length - 1) {
                state.comparedNodes.splice(
                    index + 1,
                    0,
                    state.comparedNodes.splice(index, 1)[0])
            }
        },
        RESET_COMPARISON_LIST(state : State): void {
            state.comparedNodes.splice(0, state.comparedNodes.length)
        }
    },
    actions: {
        async init({ commit } : Commit): Promise<Response.AuthenticationFailed | boolean> {
            return await http.get('/user-nodes-info')
                // eslint-disable-next-line 
                .then((response: any) => {
                    const userId = response.data.userId
                    const nodes = response.data.nodeInfoResults
                    const relations = response.data.nodeUserRelations
                    const updatedMeters = response.data.nodeMetersUpdated
                    
                    commit('SET_USER_ID', userId)
                    commit('SET_NODES', nodes)
                    commit('SET_NODE_USER_RELATIONS', relations)
                    commit('SET_UPDATED_METERS', updatedMeters)
                    return true
                })
                .catch((error : Response.AuthenticationError) => {
                    return error.response
                })
        },
        async deleteUpdatedMeters({ commit, state } : Commit, nodeId: string): Promise<Response.AuthenticationFailed | boolean> {
            // const nodeId = state.selectedNode.id
            return await http.delete(`/delete-meter-updates?nodeId=${nodeId}`)
                .then(() => {
                    commit('DELETE_UPDATED_METERS', nodeId)
                })
                .catch((error: any) => {
                    return error
                })
        }
    },
    getters: {
        UserId: (state: State) : string => state.userId,
        NodeInfos: (state: State) : Array<NodeInfo> => state.nodes,
        DetailNode: (state: State) : NodeInfo => state.detailNode,
        ComparedNodes: (state: State) : Array<NodeInfo> => state.comparedNodes,
        NodeUserRelations: (state: State) : any => state.nodeUserRelations,
        UpdatedMeters: (state: State) : any => state.updatedMeters,
        IsPreloaded: (state: State) : boolean => state.preloaded
    }
}

