import { http } from '@/http-common'

import * as Request from '@/types/network/request'
import * as Response from '@/types/network/response'

import { Admin as State } from '@/types/state'
import { Default as Commit } from '@/types/mutations'


export default {
    namespaced: true,
    state: {
        registrations: []
    },
    mutations: {
        ADD_REGISTRATION(state : State, registration: any) {
            state.registrations.push(registration)
        }
    },
    actions: {
        async register({ commit } : Commit, request: Request.Register): Promise<any> {
            return await http.post('/auth/register', request)
                .then((response: any) => {
                    response.data.url = window.location.href.match(/https*:\/\/[a-zA-Z0-9.]{3,}(:[0-9]*)?/g)?.pop() + response.data.url

                    const { email, url, expires } = response.data
                    
                    commit('ADD_REGISTRATION', { email, url, expires })
                    return response
                })
                .catch((error : Response.AuthenticationError) => {
                    return error.response
                })
        },
    },
    getters: {
        registrations: (state: State) : any[] => state.registrations
    }
}

