
import { http } from '@/http-common'

import { getHomePath } from '@/router/authentication'

import * as Request from '@/types/network/request'
import * as Response from '@/types/network/response'

import { Authentication as State } from '@/types/state'
import { Default as Commit } from '@/types/mutations'
import { AuthenticationStatus as Status }  from '@/enums'

import jwt_decode, { JwtPayload as JwtPayloadDefault } from 'jwt-decode';

import router from '@/router'

interface JwtPayload extends JwtPayloadDefault {
    role: string,
    sub: string,
    exp: number
}

export default {
    namespaced: true,
    state: {
        jwToken: '',
        refreshToken: '',
        errors: [],
        httpStatus: 0,
        status: Status.LoggedOut,
        expires: undefined,
        role: undefined,
        sub: undefined
    },
    mutations: {
        SET_AUTHENTICATION_SUCCESS(state : State, response: Response.AuthenticationSuccess): void {
            state.errors = []
            state.jwToken = response.data.token.toString()
            state.refreshToken = response.data.refreshToken.toString()
            state.httpStatus = response.status
            state.status = Status.LoggedIn
            const decoded: JwtPayload = jwt_decode(state.jwToken)
            state.sub = decoded.sub
            state.expires = decoded.exp
            state.role = decoded.role
        },
        SET_AUTHENTICATION_FAILED(state : State, response: Response.AuthenticationFailed): void {
            state.errors.splice(0, state.errors.length, ...(response?.data?.errors 
                ?? [ 'Oeps... Er ging iets fout.', ' Probeer het later nog eens.' ]))
            state.httpStatus = response?.status
            state.status = Status.Failed
        },
        LOGOUT(state : State): void {
            state.jwToken = ''
            state.refreshToken = ''
            state.status = Status.LoggedOut
            state.errors = []
            state.httpStatus = 0
            state.sub = ''
            state.expires = 0
            state.role = ''
            router.push('/login');
        },
    },
    actions: {
        login({ commit, state } : Commit, request: Request.Login): Promise<void> {
            return http.post('/auth/login', request)
                .then((response: Response.AuthenticationSuccess) => {
                    commit('SET_AUTHENTICATION_SUCCESS', response)
                    http.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`
                    router.push(getHomePath(state.role))
                })
                .catch((error : Response.AuthenticationError) => {
                    commit('SET_AUTHENTICATION_FAILED', error.response)
                })
        },
        refresh({ commit, state } : Commit): void {
            const request = {
                token: state.jwToken,
                refreshToken: state.refreshToken
            }

            http.post('/auth/refresh', request)
                .then((response: Response.AuthenticationSuccess) => 
                    commit('SET_AUTHENTICATION_SUCCESS', response))
                .catch((error : Response.AuthenticationError) => {
                    commit('SET_AUTHENTICATION_FAILED', error.response)
                })
        },
        logout({ commit } : Commit): void {
            commit('LOGOUT')
        },
        confirmRegistration({ commit } : Commit, request: Request.ConfirmRegistration): void {
            http.post('/auth/confirm', request)
                .then((response: Response.AuthenticationSuccess) => {
                    commit('SET_AUTHENTICATION_SUCCESS', response)
                    router.push('/admin');
                })
                .catch((error : Response.AuthenticationError) => {
                    commit('SET_AUTHENTICATION_FAILED', error.response)
                })
        },
    },
    getters: {
        jwToken: (state: State) : string => state.jwToken,
        refreshToken: (state: State) : string => state.refreshToken,
        errors: (state: State) : string[] => state.errors,
        role: (state: State) : string => state.role ?? '',
        status: (state: State) : number => state.status,
        expires: (state: State) : Date | string => (state.expires) ? new Date(state.expires*1000) : new Date(0),
        isExpired : (state : State) : boolean => ((state.expires ?? 0)*1000 > Number(Date.now())),
        email : (state : State) : string | undefined => state.sub
    }
}

