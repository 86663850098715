import { http } from '@/http-common'

import * as Response from '@/types/network/response'

import { EMRAPageInfo as State, NodeInfo, NodeInfoItem } from '@/types/state'
import { Default as Commit } from '@/types/mutations'


export default {
    namespaced: true,
    state: {
        detailNode: null,
        address: "",
        alias: "",
        comment: "",
        relatedUsers: [],
        systemType: 0,
        countries: [],
        preloaded: false
    },
    mutations: {
        SET_NODE_INFO(state : State, node: NodeInfo): void {
            state.detailNode = node
            state.preloaded = true
        },
        SET_ADDRESS(state: State, address: string): void {
            state.address = address
        },
        SET_ALIAS(state: State, alias: string): void {
            state.alias = alias
        },
        SET_COMMENT(state: State, comment: string): void {
            state.comment = comment
        },
        SET_OUT_OF_USE(state: State, outOfUse: boolean): void {
            state.detailNode.outOfUse = outOfUse
        },
        SET_RELATED_USERS(state : State, relations: any[]): void {
            state.relatedUsers = relations
        },
        SET_SYSTEM_TYPE(state: State, systemType: number): void {
            state.systemType = systemType
        },
        SET_COUNTRIES(state: State, countries: any[]): void {
            state.countries = countries;
        },
        DELETE_USER_RELATION(state: State, nodeAndUserId: any): void {
            const index = state.relatedUsers.findIndex((r: any) => r.nodeId == nodeAndUserId.nodeId && r.user.id == nodeAndUserId.userId)
            if (index > -1) {
                state.relatedUsers.splice(index, 1)
            }
        },
    },
    actions: {
        async init({ commit, state } : Commit): Promise<Response.AuthenticationFailed | boolean> {
            if (!state.detailNode) return false
            return await http.get(`/emra-page-info?nodeId=${state.detailNode?.id}`)
                // eslint-disable-next-line 
                .then((response: any) => {
                    commit('SET_ADDRESS', response.data.address)
                    commit('SET_ALIAS', response.data.alias)
                    commit('SET_COMMENT', response.data.comment)
                    commit('SET_RELATED_USERS', response.data.relatedUsers)
                    commit('SET_SYSTEM_TYPE', response.data.systemType)
                    commit('SET_COUNTRIES', response.data.countries)
                    return true
                })
                .catch((error : Response.AuthenticationError) => {
                    return error.response
                })
        },
        async updateAddress({ commit } : Commit, address: any): Promise<Response.AuthenticationFailed | boolean> {
            return await http.post('/update-node-address', address)
                .then((response: any) => {
                    commit('SET_ADDRESS', response.data)
                    return true
                })
                .catch((error: Response.AuthenticationError) => {
                    return error.response
                })
        },
        async updateComment({ commit } : Commit, comment: any): Promise<Response.AuthenticationFailed | boolean> {
            return await http.post('/update-node-comment', comment)
                .then((response: any) => {
                    commit('SET_COMMENT', response.data)
                    return true
                })
                .catch((error: Response.AuthenticationError) => {
                    return error.response
                })
        },
        async updateOutOfUse({ commit } : Commit, outOfUse: any): Promise<Response.AuthenticationFailed | boolean> {
            return await http.post('/update-node-out-of-use', outOfUse)
                .then((response: any) => {
                    commit('SET_OUT_OF_USE', response.data)
                    return true
                })
                .catch((error: Response.AuthenticationError) => {
                    return error.response
                })
        }
    },
    getters: {
        DetailNode: (state: State) : NodeInfo => state.detailNode,
        Address: (state: State) : string => state.address,
        Alias: (state: State) : string => state.alias,
        Comment: (state: State) : string => state.comment,
        RelatedUsers: (state: State) : any[] => state.relatedUsers,
        SystemType: (state: State) : number => state.systemType,
        Countries: (state: State) : any[] => state.countries,
        IsPreloaded: (state: State) : boolean => state.preloaded
    }
}

