import { UserRole } from '@/enums';
import store from '@/store';
import { NavigationGuardNext, Route } from 'vue-router';

import { AuthenticationStatus as Status} from '@/enums'

type beforeEnter = (to: Route, from: Route, next: NavigationGuardNext) => void

const roleValues: { [key: string]: number } = {
    'Admin': 4,
    'Moderator': 3,
    'SuperUser': 2,
    'User': 1,
    '': 0
}

export const getHomePath = (role: UserRole) => {
    switch(role) {
        case UserRole.Admin:
            return '/admin'
        case UserRole.User:
            return '/default'
        case UserRole.Moderator:
            return '/mod'
        default: 
            return '/forbidden'
    }
}

export const keepAliveHealthyTokens = () => {
    if (store.getters['identity/jwToken'] && store.getters['identity/expires'] < Date.now()) {
        store.dispatch('identity/refresh')
    }
}

const hasValidTokens = () => {
    const hasToken = store.getters['identity/jwToken'] || false
    const isExpired = store.getters['identity/expires'] < Date.now()

    if (hasToken && isExpired) {
            store.dispatch('identity/refresh')
    
            return store.getters['identity/status'] === Status.LoggedIn
    }

    return hasToken

}

/**
 * Function builder
 * Builds a function to check for authorization for a specific role.
 * Also performs token validation
 * @param role The role a user should at least have.
 * @returns A function that checks authorization and token validation.
 */
const requiresRole = (role: UserRole): beforeEnter => {
    return (to: Route, from: Route, next: NavigationGuardNext) => {
        store.getters['identity/status'] === Status.LoggedIn && 
        store.getters['identity/role'] == role
            ? next()
            : next({ name: "Login" })
    }
}

export const requiresAdmin = requiresRole(UserRole.Admin)
export const requiresModerator = requiresRole(UserRole.Moderator)
export const requiresUser = requiresRole(UserRole.User)

export const logOut = (): void => {
    store.dispatch('identity/logout')
}