import { http } from '@/http-common'

import * as Response from '@/types/network/response'

import { ModNode as State, ModNodeData, NodeInfo, NodeVariableUpdate, GlobalVariableUpdate } from '@/types/state'
import { Default as Commit } from '@/types/mutations'


export default {
    namespaced: true,
    state: {
        preloaded: false,
        updatedValue: false,
        nodeInfo: null,
        nodeData: null,
        dataStartDate: new Date(Date.now() - 1000*60*60*24*7*26),   // Half a year
        dataEndDate: new Date(Date.now())   // Up to now
    },
    mutations: {
        SET_PRELOADED(state: State, preloaded: boolean): void {
            state.preloaded = preloaded
        },
        SET_UPDATED_VALUE(state: State, updatedValue: boolean): void {
            state.updatedValue = updatedValue
        },
        async SET_NODE_INFO(state: State, nodeInfo: NodeInfo): Promise<void> {
            state.nodeInfo = nodeInfo
        },
        async SET_NODE_DATA(state: State, modNodeData: ModNodeData): Promise<void> {
            state.nodeData = modNodeData
        },
        SET_START_DATE(state: State, startDate: Date): void {
            state.dataStartDate = startDate
        },
        SET_END_DATE(state: State, endDate: Date): void {
            state.dataEndDate = endDate
        }
    },
    actions: {
        async init({ commit, state } : Commit): Promise<Response.AuthenticationFailed | boolean> {
            // commit('SET_START_DATE', new Date(Date.now() - 1000*60*60*24*7).toISOString().substr(0, 10))
            // commit('SET_END_DATE', new Date(Date.now()).toISOString().substr(0, 10))

            const startDate = new Date(Date.now() - 1000*60*60*24*7*26).toISOString().substr(0, 10) // Half a year
            const endDate = new Date(Date.now()).toISOString().substr(0, 10)    // Up to now

            commit('SET_START_DATE', startDate)
            commit('SET_END_DATE', endDate)

            const nodeInfo = state.nodeInfo
            return await http.get(`/variable-data?nodeId=${nodeInfo.id}&startDate=${startDate}&endDate=${endDate}`)
                .then(async (val: any) => {
                    const data: ModNodeData = val.data
                    await commit('SET_NODE_DATA', data)
                    commit('SET_PRELOADED', true)
                    return true
                })
                .catch((error: any) => {
                    commit('SET_PRELOADED', false)
                    return error
                })
        },
        async updateNodeData({ commit, state } : Commit, startAndEndDate: [any, any]): Promise<Response.AuthenticationFailed | boolean> {
            const startDate = startAndEndDate[0]
            const endDate = startAndEndDate[1]
            
            commit('SET_START_DATE', startDate)
            commit('SET_END_DATE', endDate)
            commit('SET_PRELOADED', false)

            const nodeInfo = state.nodeInfo
            if (nodeInfo && nodeInfo.id) {
                return await http.get(`/variable-data?nodeId=${nodeInfo.id}&startDate=${startDate}&endDate=${endDate}`)
                    .then(async (val: any) => {
                        if (val && val["data"]) {
                            const data: ModNodeData = val.data
                            await commit('SET_NODE_DATA', data)
                            commit('SET_PRELOADED', true)
                            return true
                        }
                    })
                    .catch((error: any) => {
                        commit('SET_PRELOADED', false)
                        return error
                    })
            }

            return false
        },
        async updateNodeVariable({ state, commit, dispatch, getters } : Commit, nodeVariableUpdate: NodeVariableUpdate): Promise<Response.AuthenticationFailed | boolean> {
            const nodeInfo = state.nodeInfo
            if (nodeInfo && nodeInfo.id) {
                const newNodeVariableUpdate: NodeVariableUpdate = {
                    nodeId: nodeInfo.id,
                    nodeVariableName: nodeVariableUpdate.nodeVariableName,
                    newValue: nodeVariableUpdate.newValue
                }
                await http.post(`/update-node-variable`, newNodeVariableUpdate)
                    .then(async () => {
                        commit('SET_UPDATED_VALUE', true)
                        await dispatch('updateNodeData', [getters.DataStartDate, getters.DataEndDate])
                    })
                
                return true
            }
            return false
        },
        async updateGlobalVariable({ commit, dispatch, getters } : Commit, globalVariableUpdate: GlobalVariableUpdate): Promise<Response.AuthenticationFailed | boolean> {
            const newGlobalVariableUpdate: GlobalVariableUpdate = {
                globalVariableName: globalVariableUpdate.globalVariableName,
                newValue: globalVariableUpdate.newValue
            }
            await http.post(`/update-global-variable`, newGlobalVariableUpdate)
                .then(async () => {
                    commit('SET_UPDATED_VALUE', true)
                    await dispatch('updateNodeData', [getters.DataStartDate, getters.DataEndDate])
                })

            return true
        }
    },
    getters: {
        IsPreloaded: (state: State) : boolean => state.preloaded,
        UpdatedValue: (state: State) : boolean => state.updatedValue,
        NodeInfo: (state: State) : NodeInfo => state.nodeInfo,
        NodeData: (state: State) : ModNodeData => state.nodeData,
        DataStartDate: (state: State) : Date => state.dataStartDate,
        DataEndDate: (state: State) : Date => state.dataEndDate
    }
}

