import { http } from '@/http-common'

import * as Response from '@/types/network/response'

import { EMRAConfigurationPreloadData as State } from '@/types/state'
import { Default as Commit } from '@/types/mutations'


export default {
    namespaced: true,
    state: {
        preloaded: false,
        preloadData: { }
    },
    mutations: {
        SET_PRELOADED(state: State, preloaded: boolean): void {
            state.preloaded = preloaded
        },
        SET_PRELOAD_DATA(state: State, preloadData: any): void {
            state.preloadData = preloadData
        },
        ADD_DATA(state: State, modelAndEntity: any): void {
            const list = state.preloadData[modelAndEntity.Model]
            if (!list) return

            const lowerCaseEntity: any = { }
            // Entity comes from API and has fields that start with an uppercase character, but lowercase first characters are needed on the frontend
            for (const key in modelAndEntity.Entity) {
                lowerCaseEntity[key.substring(0, 1).toLowerCase() + key.substring(1)] = modelAndEntity.Entity[key]
            }

            list.push(lowerCaseEntity)
        }
    },
    actions: {
        async init({ commit, state } : Commit): Promise<Response.AuthenticationFailed | boolean> {
            return await http.get('/emra-configuration-preload')
                .then(async (preloadData: any) => {
                    commit('SET_PRELOAD_DATA', preloadData.data)
                    commit('SET_PRELOADED', true);
                })
                .catch((error : any) => {
                    return error
                })
        }
    },
    getters: {
        IsPreloaded: (state: State) : boolean => state.preloaded,
        PreloadData: (state: State) : any => state.preloadData
    }
}

