import camelCase from 'lodash/camelCase';
import { ModuleTree } from 'vuex';

const requireModule = require.context(".", true , /\.ts$/); // Extract ts files inside
const modules: ModuleTree<unknown> = {};

requireModule.keys().forEach(fileName => {
    if (fileName === "./index.ts") return;  // reject the index.ts file

    const moduleName: string = camelCase(fileName.replace(/(\.\/|\.ts)/g, ""));
    modules[moduleName] = requireModule(fileName).default;
});

export default modules;
