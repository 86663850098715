import { http } from '@/http-common'

import * as Response from '@/types/network/response'

import { DetailNode as State, MeterWithMeterUnits, NodeInfo, ShownUnit, ShownUnitWithShouldUpdateFlag } from '@/types/state'
import { Default as Commit } from '@/types/mutations'


const defaultState = (nodeInfo: NodeInfo|null = null) => {
    return {
        nodeInfo: nodeInfo,
        meters: null,
        shownUnits: [],
        dataStartDate: new Date(Date.now() - 1000*60*60*24*7),//null,
        dataEndDate: new Date(Date.now()),//null,
        preloaded: false,
        dataLoaded: false
    }
}


export default {
    namespaced: true,
    state: defaultState(),
    mutations: {
        async SET_NODE_INFO(state: State, nodeInfo: NodeInfo): Promise<void> {
            state.nodeInfo = nodeInfo
        },
        SET_PRELOADED(state: State, preloaded: boolean): void {
            state.preloaded = preloaded
        },
        SET_DATA_LOADED(state: State, dataLoaded: boolean): void {
            state.dataLoaded = dataLoaded
        },
        SET_METERS(state: State, meters: Array<MeterWithMeterUnits>): void {
            meters.forEach(m => {
                if (!m.unitIds) m.unitIds = []
                m.displayedMeterUnitsWithData.forEach(dmu => {
                    if (!m.unitIds.includes(dmu.meterUnitId)) m.unitIds.push(dmu.meterUnitId)
                })
            })
            meters.sort((a, b) => {
                const lengthDiff = a.unitIds.length - b.unitIds.length
                if (lengthDiff != 0) return lengthDiff
                return a.name < b.name ? -1 : 1
            })
            state.meters = meters
            state.preloaded = true
        },
        SET_OUT_OF_USE(state: State, outOfUse: boolean): void {
            state.nodeInfo.outOfUse = outOfUse
        },
        RESET_STATE(state: State) : void {
            Object.assign(state, defaultState(state.nodeInfo))
        },
        TOGGLE_SHOWN_UNIT_WITH_DATA(state: State, shownUnitWithFlag: ShownUnitWithShouldUpdateFlag): void {
            const shownUnit = shownUnitWithFlag.shownUnit
            const updateIfPresent = shownUnitWithFlag.shouldUpdate

            const alreadyExistsIndex = state.shownUnits.findIndex(su => su.meterUnitId == shownUnit.meterUnitId)

            if (!(alreadyExistsIndex >= 0 && !updateIfPresent)) {
                shownUnit.data.sort((a, b) => a.timestamp > b.timestamp ? 1 : -1)
            }

            if (alreadyExistsIndex >= 0) {
                if (updateIfPresent) {
                    state.shownUnits[alreadyExistsIndex].data = shownUnit.data
                } else {
                    state.shownUnits.splice(alreadyExistsIndex, 1)
                }
            } else {
                state.shownUnits.push(shownUnit)
            }
        },
        SET_START_DATE(state: State, startDate: Date): void {
            state.dataStartDate = startDate
        },
        SET_END_DATE(state: State, endDate: Date): void {
            state.dataEndDate = endDate
        }
    },
    actions: {
        async init({ commit, state } : Commit): Promise<Response.AuthenticationFailed | boolean> {
            commit('RESET_STATE')
            
            state.shownUnits.forEach((su: ShownUnit) => {
                const suWithFlag: ShownUnitWithShouldUpdateFlag = { shownUnit: su, shouldUpdate: false }
                commit('TOGGLE_SHOWN_UNIT_WITH_DATA', suWithFlag)
            });

            commit('SET_START_DATE', new Date(Date.now() - 1000*60*60*24*7).toISOString().substr(0, 10))
            commit('SET_END_DATE', new Date(Date.now()).toISOString().substr(0, 10))

            const nodeInfo = state.nodeInfo
            if (nodeInfo && nodeInfo.id) {
                return await http.get(`/detail-node?nodeId=${nodeInfo.id}`)
                .then(async (meters: any) => {
                    if (meters && meters["data"]) {
                        const metersData = meters["data"]
                        metersData.forEach((md: MeterWithMeterUnits) => {
                            if (md.modelName == "Elektriciteitsmeter") {
                                md.displayedMeterUnitsWithData.forEach(dmu => {
                                    if (dmu.referenceName == "Opgewekte warmte") {
                                        switch (md.name.toLowerCase()) {
                                            case "warmtepomp":
                                            case "warmte pomp":
                                            case "pompen":
                                            case "airsep":
                                            case "regelkast":
                                                dmu.referenceName = "Verbruik"
                                                break
                                            case "zon pv":
                                            case "zon-pv":
                                                dmu.referenceName = "Teruggeleverd"
                                                break
                                            default:
                                                break
                                        }
                                    }
                                })
                            }
                        });
                        commit('SET_METERS', metersData)
                        return true
                    }
                })
                .catch((error: any) => {
                    commit('SET_PRELOADED', true)
                    return error
                })
            }
            commit('SET_PRELOADED', true)
            return false
        }
    },
    getters: {
        NodeInfo: (state: State) : NodeInfo => state.nodeInfo,
        Meters: (state: State) : Array<MeterWithMeterUnits> => state.meters,
        IsPreloaded: (state: State) : boolean => state.preloaded,
        IsDataLoaded: (state: State) : boolean => state.dataLoaded,
        DataStartDate: (state: State) : Date => state.dataStartDate,
        DataEndDate: (state: State) : Date => state.dataEndDate,
        ShownUnitsWithinDateRange: (state: State) : Array<ShownUnit> => {
            const suWithinRange: Array<ShownUnit> = []
            state.shownUnits.forEach(su => {
                suWithinRange.push({
                    meterUnitId: su.meterUnitId,
                    data: su.data.filter(d => d.timestamp >= state.dataStartDate && d.timestamp <= state.dataEndDate),
                    dataFunc: su.dataFunc,
                    graphColorHex: su.graphColorHex,
                    decimals: su.decimals
                })
            });
            return suWithinRange
        },
        ShownUnits: (state: State) : Array<ShownUnit> => state.shownUnits
    }
}

