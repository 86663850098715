import { http } from '@/http-common'

import * as Response from '@/types/network/response'

import { NodeDataList as State, NodeData, CalcDataField } from '@/types/state'
import { Default as Commit } from '@/types/mutations'
import { AxiosResponse } from 'axios'


export default {
    namespaced: true,
    state: {
        calcDataFields: [],
        nodeDatas: [],
        dataStartDate: null,
        dataEndDate: null,
        preloaded: false
    },
    mutations: {
        SET_CALC_DATA_FIELDS(state: State, calcDataFields: Array<CalcDataField>): void {
            state.calcDataFields = calcDataFields
        },
        SET_NODE_DATAS(state: State, nodeDatas: Array<NodeData>): void {
            state.nodeDatas = nodeDatas
            state.preloaded = true
        },
        SET_PRELOADED(state: State, preloaded: boolean): void {
            state.preloaded = preloaded
        },
        SET_START_DATE(state: State, startDate: Date): void {
            state.dataStartDate = startDate
        },
        SET_END_DATE(state: State, endDate: Date): void {
            state.dataEndDate = endDate
        }
    },
    actions: {
        async init({ commit, state } : Commit): Promise<Response.AuthenticationFailed | boolean> {
            commit('SET_START_DATE', new Date(Date.now() - 1000*60*60*24*7).toISOString().substr(0, 10))
            commit('SET_END_DATE', new Date(Date.now()).toISOString().substr(0, 10))

            return await http.get('/calculated-data-fields')
                .then(async (calcDataFields: any) => {
                    commit('SET_CALC_DATA_FIELDS', calcDataFields)

                    return await http.get(`/user-node-datas?startDate=${state.dataStartDate}&endDate=${state.dataEndDate}`)
                        // eslint-disable-next-line
                        .then((response: any) => {
                            const nodeDatas = response.data
                            
                            commit('SET_NODE_DATAS', nodeDatas)
                            return true
                        })
                        .catch((error : Response.AuthenticationError) => {
                            return error.response
                        })
                })
                .catch((error : any) => {
                    return error
                })
        },
        async getNodeDatasInDateRange({ commit } : Commit, startAndEndDate: [any, any]): Promise<AxiosResponse<any>> {
            return http.get(`/user-node-datas?startDate=${startAndEndDate[0]}&endDate=${startAndEndDate[1]}`)
        }
    },
    getters: {
        CalcDataFields: (state: State) : Array<CalcDataField> => state.calcDataFields,
        NodeDatas: (state: State) : Array<NodeData> => state.nodeDatas,
        IsPreloaded: (state: State) : boolean => state.preloaded
    }
}

