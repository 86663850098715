import { http } from '@/http-common'

import * as Response from '@/types/network/response'

import { MeterPagePreload as State, NodeInfo, Meter } from '@/types/state'
import { Default as Commit } from '@/types/mutations'


const defaultState = (nodeInfo: NodeInfo|null = null) => {
    return {
        preloaded: false,
        preloadData: { },
        selectedNode: nodeInfo
    }
}

const equalObject = (obj1: any, obj2: any) => {
    for (const k in obj1) {
        if (!Object.prototype.hasOwnProperty.call(obj2, k) || obj1[k] !== obj2[k])
            return false
    }
    return true
}


export default {
    namespaced: true,
    state: defaultState(),
    mutations: {
        SET_PRELOADED(state: State, preloaded: boolean): void {
            state.preloaded = preloaded
        },
        async SET_PRELOAD_DATA(state: State, preloadData: any): Promise<void> {
            state.preloadData = preloadData
        },
        async SET_NODE_INFO(state: State, nodeInfo: NodeInfo): Promise<void> {
            state.selectedNode = nodeInfo
        },
        ADD_DATA(state: State, modelAndEntity: any): void {
            const list = state.preloadData[modelAndEntity.Model]
            if (!list) return

            const lowerCaseEntity: any = { }
            // Entity comes from API and has fields that start with an uppercase character, but lowercase first characters are needed on the frontend
            for (const key in modelAndEntity.Entity) {
                lowerCaseEntity[key.substring(0, 1).toLowerCase() + key.substring(1)] = modelAndEntity.Entity[key]
            }

            if (!list.find((e: any) => equalObject(e, lowerCaseEntity))) {
                if (lowerCaseEntity["name"]) {
                    // Insert alphabetically if there is a "name" attribute; for display purposes
                    let i = 0
                    while (i < list.length && (!list[i]["name"] || list[i]["name"] < lowerCaseEntity["name"])) ++i
                    list.splice(i, 0, lowerCaseEntity)
                } else {
                    // Otherwise just insert
                    list.push(lowerCaseEntity)
                }
            }
        },
        EDIT_METER(state: State, editedMeter: any): void {
            if (editedMeter.Id) {
                const meter = state.preloadData["meters"].find((m: Meter) => m.id == editedMeter.Id)
                if (meter) {
                    meter.name = editedMeter.Name
                    meter.address = editedMeter.Address
                    meter.encryptionKey = editedMeter.EncryptionKey
                    // if (!state.preloadData["updatedMeters"].find((um: any) => um.meterId == editedMeter.Id))
                    //     state.preloadData["updatedMeters"].push({ nodeId: state.selectedNode.id, meterId: editedMeter.Id })
                }
            }
        },
        DELETE_UPDATED_METERS(state: State, nodeId: string): void {
            const list: any[] = state.preloadData["updatedMeters"]
            if (!list) return

            const nodesWithThisId = list.filter((el: any) => el.nodeId == nodeId)
            nodesWithThisId.forEach((el: any) => {
                list.splice(list.findIndex((el2: any) => el2.nodeId == nodeId && el2.meterId == el.meterId), 1)
            })
        },
        RESET_STATE(state: State) : void {
            Object.assign(state, defaultState(state.selectedNode))
        }
    },
    actions: {
        async init({ commit, state } : Commit): Promise<Response.AuthenticationFailed | boolean> {
            const nodeInfo = state.selectedNode
            return await http.get(`/meter-page-preload?nodeId=${nodeInfo.id}`)
                .then(async (preloadData: any) => {
                    await commit('SET_PRELOAD_DATA', preloadData.data)
                    commit('SET_PRELOADED', true)
                })
                .catch((error: any) => {
                    return error
                })
        },
        async initWithNodeId({ commit, state } : Commit, nodeId: string): Promise<Response.AuthenticationFailed | boolean> {
            return await http.get(`/meter-page-preload?nodeId=${nodeId}`)
                .then(async (preloadData: any) => {
                    await commit('SET_PRELOAD_DATA', preloadData.data)
                    commit('SET_PRELOADED', true)
                })
                .catch((error: any) => {
                    return error
                })
        },
        async addUpdatedMeter({ commit, state } : Commit, meterUpdateInsert: any): Promise<Response.AuthenticationFailed | boolean> {
            return await http.post('/meter-update', meterUpdateInsert)
                .then(() => {
                    commit('ADD_DATA', { Model: "updatedMeters", Entity: meterUpdateInsert })
                })
                .catch((error: any) => {
                    return error
                })
        },
        async editMeter({ commit } : Commit, meter: any) {
            return await http.post('/meter-edit', meter)
                .then((result: any) => {
                    commit("EDIT_METER", meter)
                    return { err: null, entity: result.data }
                })
                .catch((error: any) => {
                    return { err: error }
                })
        }
    },
    getters: {
        IsPreloaded: (state: State) : boolean => state.preloaded,
        PreloadData: (state: State) : any => state.preloadData,
        SelectedNode: (state: State) : NodeInfo => state.selectedNode,
        UpdatedMeters: (state: State) : Array<any> => state.preloadData?.updatedMeters,
    }
}

